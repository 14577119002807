import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, CircularProgress } from "@mui/material";
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add'; // Import the Add icon
import { API_URL } from '../constants/AppConstants';

const CustomizeDashboard = ({ onAddChart, context }) => {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(`${API_URL}/listMetrics?context=${context}`)
      .then((response) => {
        console.log('Fetched metrics:', response.data);
        setMetrics(response.data.Data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching metrics data');
        setLoading(false);
      });
  }, [context]);

  const handleAddChart = (metric) => {
    // Here, you need to decide which component to use for each metric
    // For now, using a placeholder component
    const ChartComponent = () => <Typography>{metric} Chart</Typography>;

    onAddChart(metric, ChartComponent);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography>{error}</Typography>;


  return (
    <Box sx={{
      width: { xs: '100%', sm: '250px' },
      height: '100%',
      backgroundColor: '#1a1a2e',
      color: '#fff',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Customize Dashboard
        </Typography>
      </Box>
      
      <Typography variant="body2" sx={{ mb: 2 }}>
        Select metrics
      </Typography>

      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {Object.keys(metrics).map((category) => (
          <Box key={category} sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {category}
            </Typography>
            <List disablePadding>
              {Array.isArray(metrics[category]) ? (
                metrics[category].map((metric) => (
                  <ListItem 
                    key={metric} 
                    disableGutters
                    sx={{ 
                      py: 0.5, 
                      '&:hover': { 
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        borderRadius: '4px'
                      },
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      position: 'relative'  // Add relative positioning
                    }}
                  >
                    <ListItemText 
                      primary={metric} 
                      primaryTypographyProps={{ 
                        variant: 'body2',
                        sx: { color: '#b8b8b8' }
                      }} 
                    />
                    <Box sx={{
                      position: 'absolute',  // Position button absolutely
                      right: 0,
                      opacity: 0,  // Initially hidden
                      transition: 'opacity 0.3s ease',
                      '&:hover': { opacity: 1 },  // Show button on hover
                      '&:hover .addButton': {
                        opacity: 1
                      }
                    }}>
                      <IconButton 
                        onClick={() => handleAddChart(metric)}
                        sx={{
                          color: '#fff',
                          borderRadius: '50%',
                          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                        }}
                        className="addButton"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))
              ) : (
                <Typography>No metrics available</Typography>
              )}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomizeDashboard;


// import React, { useState, useEffect } from 'react';
// import { Box, Typography, List, ListItem, ListItemText, IconButton, CircularProgress } from "@mui/material";
// import AddIcon from '@mui/icons-material/Add';
// import SalesByMenuCategoryChart from './SalesByMenuCategoryChart'; // Import your chart components

// const CustomizeDashboard = ({ onAddChart }) => {
//   const [metrics, setMetrics] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     fetch('/metrics.json') 
//       .then((response) => response.json())
//       .then((data) => {
//         setMetrics(data.Data);
//         setLoading(false);
//       })
//       .catch(() => {
//         setError('Error fetching metrics data');
//         setLoading(false);
//       });
//   }, []);

//   const handleAddChart = (metric) => {
//     const chartMap = {
//       'Sales by Menu Category': SalesByMenuCategoryChart,
      
//     };

//     const ChartComponent = chartMap[metric];
//     if (ChartComponent) {
//       onAddChart({ id: metric, component: ChartComponent });
//     }
//   };

//   if (loading) return <CircularProgress />;
//   if (error) return <Typography>{error}</Typography>;

//   return (
//     <Box sx={{
//       width: { xs: '100%', sm: '250px' },
//       height: '100%',
//       backgroundColor: '#1a1a2e',
//       color: '#fff',
//       padding: '20px',
//       display: 'flex',
//       flexDirection: 'column',
//       overflowY: 'auto'
//     }}>
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//         <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//           Customize Dashboard
//         </Typography>
//       </Box>
      
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         Select metrics
//       </Typography>

//       <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
//         {Object.keys(metrics).map((category) => (
//           <Box key={category} sx={{ mb: 3 }}>
//             <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
//               {category}
//             </Typography>
//             <List disablePadding>
//               {Array.isArray(metrics[category]) ? (
//                 metrics[category].map((metric) => (
//                   <ListItem 
//                     key={metric} 
//                     disableGutters
//                     sx={{ 
//                       py: 0.5, 
//                       '&:hover': { 
//                         backgroundColor: 'rgba(255,255,255,0.1)',
//                         borderRadius: '4px',
//                         '.add-button': { 
//                           visibility: 'visible'
//                         }
//                       },
//                       display: 'flex',
//                       alignItems: 'center',
//                       justifyContent: 'space-between'
//                     }}
//                   >
//                     <ListItemText 
//                       primary={metric} 
//                       primaryTypographyProps={{ 
//                         variant: 'body2',
//                         sx: { color: '#b8b8b8' }
//                       }} 
//                     />
//                     <IconButton 
//                       onClick={() => handleAddChart(metric)}
//                       sx={{
//                         visibility: 'hidden', // Initially hidden
//                         color: '#fff',
//                         borderRadius: '50%',
//                         '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
//                       }}
//                       className="add-button" // Add class for CSS control
//                     >
//                       <AddIcon />
//                     </IconButton>
//                   </ListItem>
//                 ))
//               ) : (
//                 <Typography>No metrics available</Typography>
//               )}
//             </List>
//           </Box>
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default CustomizeDashboard;
