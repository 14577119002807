// import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, format, addDays } from 'date-fns';

// export const getDateRangeByUserRole = (
//   userRoles: number[],
//   month: string = 'October',
//   week: string = 'Week 1'
// ): { fromDate: string; toDate: string } => {
//   const today = new Date();

  
//   if (userRoles.includes(1)) {
//     const monthIndex = [
//       'January',
//       'February',
//       'March',
//       'April',
//       'May',
//       'June',
//       'July',
//       'August',
//       'September',
//       'October',
//       'November',
//       'December',
//     ].indexOf(month);
//     const selectedDate = new Date(today.getFullYear(), monthIndex, 1);
//     const fromDate = format(startOfMonth(selectedDate), 'yyyy-MM-dd');
//     const toDate = format(endOfMonth(selectedDate), 'yyyy-MM-dd');
//     return { fromDate, toDate };
//   } else if (userRoles.includes(2)) {
//     const currentYear = today.getFullYear();
//     const currentMonthIndex = today.getMonth(); // 0-based index
//     const monthStart = new Date(currentYear, currentMonthIndex, 1);
  
//     let fromDate, toDate;
  
//     switch (week) {
//       case 'Week 1':
//         fromDate = format(monthStart, 'yyyy-MM-dd');
//         toDate = format(addDays(monthStart, 6), 'yyyy-MM-dd');
//         break;
//       case 'Week 2':
//         fromDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 7), 'yyyy-MM-dd');
//         toDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 13), 'yyyy-MM-dd');
//         break;
//       case 'Week 3':
//         fromDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 14), 'yyyy-MM-dd');
//         toDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 20), 'yyyy-MM-dd');
//         break;
//       case 'Week 4':
//         fromDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 21), 'yyyy-MM-dd');
//         toDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 28), 'yyyy-MM-dd');
//         break;
//       case 'Week 5':
//         fromDate = format(addDays(startOfWeek(monthStart, { weekStartsOn: 1 }), 28), 'yyyy-MM-dd');
//         toDate = format(endOfMonth(monthStart), 'yyyy-MM-dd');
//         break;
//       default:
//         // Default to current week if no valid week is selected
//         fromDate = format(startOfWeek(today, { weekStartsOn: 1 }), 'yyyy-MM-dd');
//         toDate = format(endOfWeek(today, { weekStartsOn: 1 }), 'yyyy-MM-dd');
//     }
//     return { fromDate, toDate };
//   } else {
    
//     const fromDate = format(today, 'yyyy-MM-dd');
//     const toDate = format(today, 'yyyy-MM-dd');
//     return { fromDate, toDate };
//   }
// };
import { startOfMonth, endOfMonth, addDays, format, parse } from 'date-fns';

export const getDateRangeByUserRole = (
  userRoles: number[],
  selectedMonth: string | null = null,
  selectedWeek: string | null = null
): { fromDate: string | null; toDate: string | null } => {
  const today = new Date();
  
  // Parse month format (e.g., "Oct-23" to Date object)
  const parseMonthString = (monthStr: string): Date => {
    const [month, year] = monthStr.split('-');
    const fullYear = parseInt(year, 10);
    return parse(month, 'MMM', new Date(fullYear, 0, 1));
  };

  // Parse week format (e.g., "Oct-Week1" to start and end dates)
  const parseWeekString = (weekStr: string): { start: Date; end: Date } => {
    const [month, weekPart] = weekStr.split('-');
    const weekNum = parseInt(weekPart.replace('Week', ''), 10);
    
    // Get the first day of the month
    const currentYear = today.getFullYear();
    const monthDate = parse(month, 'MMM', new Date(currentYear, 0, 1));
    const monthStart = startOfMonth(monthDate);
    
    // Calculate week start and end dates
    const weekStart = addDays(monthStart, (weekNum - 1) * 7);
    const weekEnd = addDays(weekStart, 6);
    
    return { start: weekStart, end: weekEnd };
  };

  // If the selectedMonth or selectedWeek is not provided, return null to avoid API call
  if (!selectedMonth && !selectedWeek) {
    return { fromDate: null, toDate: null };
  }

  if (userRoles.includes(1) && selectedMonth) {
    // Monthly view for role 1
    const selectedDate = parseMonthString(selectedMonth);
    const fromDate = format(startOfMonth(selectedDate), 'yyyy-MM-dd');
    const toDate = format(endOfMonth(selectedDate), 'yyyy-MM-dd');
    return { fromDate, toDate };
  } else if (userRoles.includes(2) && selectedWeek) {
    // Weekly view for role 2
    const { start, end } = parseWeekString(selectedWeek);
    const fromDate = format(start, 'yyyy-MM-dd');
    const toDate = format(end, 'yyyy-MM-dd');
    return { fromDate, toDate };
  }

  // Default case: If no valid role or selection, return null to avoid triggering the API
  return { fromDate: null, toDate: null };
};
