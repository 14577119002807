import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Sidebar from "../components/Sidebar";
import CustomizeDashboard from "../components/CustomizeDashboard";
// import DemandForecasting from "../components/DemandForecasting";
// import InventoryTurnoverRate from "../components/InventoryTurnoverRate";
import PieChartComponent from "../components/PieChartComponent";
import LineChartComponent from "../components/LineChartComponent";
import BarChartComponent from "../components/charts/BarChartComponent";
import axios from "axios";
import HorizontalBarChartComponent from "../components/HorizontalBarChartComponent";
import GroupedBarChartComponent from "../components/GroupedBarChartComponent";
import StackedBarChartComponent from "../components/StackedBarChartComponent";
import { API_URL } from '../constants/AppConstants';

const SortableItem = React.memo(({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </Box>
  );
});

const Dashboard = () => {
  const [uniqueItems, setUniqueItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showCustomizeDashboard, setShowCustomizeDashboard] = useState(false);
  const [dashboardData, setDashboardData] = useState({ charts: [] });
  const [context, setContext] = useState(localStorage.getItem('dashboardContext') || 'salesStrategy');

  const staticCharts = useMemo(() => [
    // { id: 'demandForecasting', component: DemandForecasting, title: 'Demand Forecasting' },
    // { id: 'inventoryTurnover', component: InventoryTurnoverRate, title: 'Inventory Turnover Rate' },
  ], []);

  const contextTitleMap = useMemo(() => ({
    salesStrategy: { title: "Sales Strategy Dashboard" },
    productStrategy: { title: "Product Strategy Dashboard" },
    consumerdemographicsStrategy: { title: "Consumer Demographics Dashboard" },
    consumerbehaviourStrategy: { title: "Consumer Behaviour Dashboard" }
  }), []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchUniqueItems = async () => {
      try {
        const response = await axios.get(`${API_URL}/unique-items`);
        setUniqueItems(response.data);
      } catch (error) {
        console.error("There was an error fetching the unique items!", error);
      }
    };

    const fetchDashboardData = async () => {
      try {
        const payload = {
          summary: "Your average transaction value per order has risen by 8%",
          "metrics": [
            "Discount Impacts on Sales","Sales Split by Charges","Sales By Takeaway vs Dine-in",
            "Sales By Seasonal Trends"]
        };
        const response = await axios.post(`${API_URL}/LoadMetricsToCanvas`, payload);
        console.log("API response:", response.data); 
        setDashboardData(response.data);
      } catch (err) {
        console.error('Failed to fetch dashboard data', err);
      }
    };

    fetchUniqueItems();
    fetchDashboardData();
  }, []);

  const handleItemChange = useCallback((event) => {
    const { target: { value } } = event;
    setSelectedItems(typeof value === 'string' ? value.split(',') : value);
  }, []);

  const toggleCustomizeDashboard = useCallback(() => {
    setShowCustomizeDashboard(prev => !prev);
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const allCharts = getAllCharts();
      const oldIndex = allCharts.findIndex((chart) => chart.id === active.id);
      const newIndex = allCharts.findIndex((chart) => chart.id === over.id);
      
      const newCharts = arrayMove(allCharts, oldIndex, newIndex);
      
      setDashboardData((prevData) => ({
        ...prevData,
        charts: newCharts.filter((chart) => !chart.component),
      }));
    }
  }, [dashboardData, staticCharts]);

  const renderChart = useCallback((chart) => {
    if (chart.component) {
      return <chart.component selectedItems={selectedItems} />;
    }
    const { title, chart_type, data } = chart; // Destructure the title from the chart object
    switch (chart_type) {
      case 'pie_chart':
        return <PieChartComponent data={data} title={title} />;
      case 'line_chart':
        return <LineChartComponent data={data} title={title} />;
      case 'bar_chart':
        return <BarChartComponent data={data} title={title} />;
      case 'bar_chart_horizontal':
      return <HorizontalBarChartComponent data={data} title={title} />;
      case 'grouped_bar_chart':
      return <GroupedBarChartComponent data={data} title={title} />;
      case 'stacked_bar_chart':
      return <StackedBarChartComponent data={data} title={title} />;
      default:
        return <Typography>Unsupported chart type: {chart_type}</Typography>;
    }
  }, [selectedItems]);

  const getAllCharts = useCallback(() => {
    const apiCharts = dashboardData ? dashboardData.charts.map(chart => ({
      ...chart,
      id: chart.title,
    })) : [];
    return [...staticCharts, ...apiCharts];
  }, [dashboardData, staticCharts]);

  const allCharts = useMemo(() => getAllCharts(), [getAllCharts]);

  return (
    <Box display="flex" height="100%">
      <Sidebar />
      <Box flex={1} display="flex" flexDirection="row">
        {showCustomizeDashboard && (
          <Box sx={{
            width: { xs: '100%', sm: '250px' },
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
            height: '100%',
            transition: 'width 0.3s ease',
          }}>
            <CustomizeDashboard context={context} />
          </Box>
        )}
        <Box flex={1} sx={{
          background: "linear-gradient(135deg, #090912 0%, #1e1e2e 50%, #301450 100%)",
          color: "#FFFFFF",
          padding: "20px",
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          transition: 'margin-left 0.3s ease',
          marginLeft: showCustomizeDashboard ? { xs: '0', sm: '30px' } : '0',
        }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "20px" }}>
            <Box>
              <Typography variant="h4" sx={{
                fontWeight: "bold",
                background: "linear-gradient(45deg, #bb86fc 30%, #6c63ff 90%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginBottom: "10px",
              }}>
                {contextTitleMap[context]?.title || "Dashboard"}
              </Typography>
              <Box display="flex" gap={2}>
                <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>
                  Jul 12-13, 2024
                </Button>
                <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>
                  Compare to: July 01 - 03, 2024
                </Button>
              </Box>
            </Box>
            <Button 
              variant="contained" 
              onClick={toggleCustomizeDashboard}
              sx={{
                background: "linear-gradient(45deg, #bb86fc 30%, #6c63ff 90%)",
                borderRadius: "20px",
                marginRight: "60px",
                color: "#FFFFFF",
                padding: "10px 20px",
                fontWeight: "bold",
              }}
            >
              {showCustomizeDashboard ? 'Hide Metrics' : 'Add Metrics to Dashboard'}
            </Button>
          </Box>

          {/* <Box sx={{ marginBottom: "20px" }}>
            <FormControl sx={{ minWidth: 300 }}>
              <InputLabel id="multi-select-label" sx={{ color: "#FFFFFF" }}>Select Food</InputLabel>
              <Select
                labelId="multi-select-label"
                multiple
                value={selectedItems}
                onChange={handleItemChange}
                renderValue={(selected) => selected.join(', ')}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
              >
                {uniqueItems.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={selectedItems.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}

          <DndContext 
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext 
              items={allCharts.map(chart => chart.id)}
              strategy={verticalListSortingStrategy}
            >
              <Box display="flex" flexWrap="wrap" gap="20px" p={2}>
                {allCharts.map((chart) => (
                  <SortableItem key={chart.id} id={chart.id}>
                    <Box width="100%" minWidth="500px">
                      {renderChart(chart)}
                    </Box>
                  </SortableItem>
                ))}
              </Box>
            </SortableContext>
          </DndContext>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Dashboard);


// import React, { useState, useEffect } from "react";
// import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
// import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
// import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
// import { useSortable } from '@dnd-kit/sortable';
// import { CSS } from '@dnd-kit/utilities';
// import Sidebar from "../components/Sidebar";
// import CustomizeDashboard from "../components/CustomizeDashboard";
// import DemandForecasting from "../components/DemandForecasting";
// import InventoryTurnoverRate from "../components/InventoryTurnoverRate";
// import TopSellingItems from "../components/TopSellingItems";
// import SlowMovingItems from "../components/SlowMovingItems";
// import axios from "axios";
// import PieChartComponent from "../components/PieChartComponent";
// import LineChartComponent from "../components/LineChartComponent";



// const SortableItem = ({ id, children }) => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     transform,
//     transition,
//   } = useSortable({ id });

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//   };

//   return (
//     <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
//       {children}
//     </Box>
//   );
// };

// const Dashboard = () => {
//   const [uniqueItems, setUniqueItems] = useState([]);
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [showCustomizeDashboard, setShowCustomizeDashboard] = useState(false);

//   const [charts, setCharts] = useState([
//     { id: 'demandForecasting', component: DemandForecasting },
//     { id: 'inventoryTurnover', component: InventoryTurnoverRate },
//     { id: 'pieChart', component: PieChartComponent },
//     { id: 'lineChart', component: LineChartComponent },
//   ]);

//   const [dashboardData, setDashboardData] = useState(null);
//   const [context, setContext] = useState(localStorage.getItem('dashboardContext') || 'salesStrategy');
//   const contextTitleMap = {
//     salesStrategy:{
//       title:"Sales Strategy Dashboard"
//     }, 
//     productStrategy:{
//       title:"Product Strategy Dashboard"
//     },
//     consumerdemographicsStrategy:{
//       title:"Consumer Demographics Dashboard"
//     },
//     consumerbehaviourStrategy:{
//       title:"Consumer Behaviour Dashboard"
//     }

//   }

//   const sensors = useSensors(
//     useSensor(PointerSensor),
//     useSensor(KeyboardSensor, {
//       coordinateGetter: sortableKeyboardCoordinates,
//     })
//   );

//   useEffect(() => {
//     axios.get("https://consumer.backend.looquplab.com/unique-items")
//       .then((response) => {
//         setUniqueItems(response.data);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the unique items!", error);
//       });
  

//   const fetchDashboardData = async () => {
//     try {
//       const payload = {
//         summary: "Your average transaction value per order has risen by 8%",
//          "metrics": ["Weekly Sales", "Sales By Menu Category","Sales by Payment Method","Average Transaction Value"]
//       };
//       const response = await axios.post('https://consumer.backend.looquplab.com/LoadMetricsToCanvas', payload);
//       setDashboardData(response.data);
//     } catch (err) {
//       console.error('Failed to fetch dashboard data', err);
//     }
//   };

//   fetchDashboardData();
// }, []);

//   useEffect(() => {
//     const storedContext = localStorage.getItem('dashboardContext');
//     if (storedContext) {
//       setContext(storedContext);
//     }
//   }, []);
//   const handleItemChange = (event) => {
//     const { target: { value } } = event;
//     setSelectedItems(typeof value === 'string' ? value.split(',') : value);
//   };

//   const toggleCustomizeDashboard = () => {
//     setShowCustomizeDashboard(!showCustomizeDashboard);
//   };

//   const handleAddChart = (chart) => {
//     setCharts((prevCharts) => [...prevCharts, chart]);
//   };

//   const handleDragEnd = (event) => {
//     const { active, over } = event;

//     if (active.id !== over.id) {
//       setCharts((items) => {
//         const oldIndex = items.findIndex((item) => item.id === active.id);
//         const newIndex = items.findIndex((item) => item.id === over.id);
        
//         return arrayMove(items, oldIndex, newIndex);
//       });
//     }
//   };



//   return (
//     <Box display="flex" height="100%">
//       <Sidebar />
//       <Box flex={1} display="flex" flexDirection="row">
//         {showCustomizeDashboard && (
//           <Box sx={{
//             width: { xs: '100%', sm: '250px' },
//             borderRight: '1px solid rgba(255, 255, 255, 0.12)',
//             height: '100%',
//             transition: 'width 0.3s ease',
//           }}>
//             <CustomizeDashboard onAddChart={handleAddChart} context={context} />
//           </Box>
//         )}
//         <Box flex={1} sx={{
//           background: "linear-gradient(135deg, #090912 0%, #1e1e2e 50%, #301450 100%)",
//           color: "#FFFFFF",
//           padding: "20px",
//           overflowY: 'auto',
//           display: 'flex',
//           flexDirection: 'column',
//           transition: 'margin-left 0.3s ease',
//           marginLeft: showCustomizeDashboard ? { xs: '0', sm: '30px' } : '0',
//         }}>
//           <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: "20px" }}>
//             <Box>
//               <Typography variant="h4" sx={{
//                 fontWeight: "bold",
//                 background: "linear-gradient(45deg, #bb86fc 30%, #6c63ff 90%)",
//                 WebkitBackgroundClip: "text",
//                 WebkitTextFillColor: "transparent",
//                 marginBottom: "10px",
//               }}>
//                  {contextTitleMap[context]?.title || "Dashboard"}
//               </Typography>
//               <Box display="flex" gap={2}>
//                 <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>
//                   Jul 12-13, 2024
//                 </Button>
//                 <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>
//                   Compare to: July 01 - 03, 2024
//                 </Button>
//               </Box>
//             </Box>
//             <Button 
//               variant="contained" 
//               onClick={toggleCustomizeDashboard}
//               sx={{
//                 background: "linear-gradient(45deg, #bb86fc 30%, #6c63ff 90%)",
//                 borderRadius: "20px",
//                 marginRight: "60px",
//                 color: "#FFFFFF",
//                 padding: "10px 20px",
//                 fontWeight: "bold",
//               }}
//             >
//               {showCustomizeDashboard ? 'Hide Metrics' : 'Add Metrics to Dashboard'}
//             </Button>
//           </Box>

//           <Box sx={{ marginBottom: "20px" }}>
//             <FormControl sx={{ minWidth: 300 }}>
//               <InputLabel id="multi-select-label" sx={{ color: "#FFFFFF" }}>Select Food</InputLabel>
//               <Select
//                 labelId="multi-select-label"
//                 multiple
//                 value={selectedItems}
//                 onChange={handleItemChange}
//                 renderValue={(selected) => selected.join(', ')}
//                 sx={{
//                   backgroundColor: "rgba(255, 255, 255, 0.1)",
//                   borderRadius: "8px",
//                   color: "#FFFFFF",
//                   "& .MuiOutlinedInput-notchedOutline": {
//                     borderColor: "transparent",
//                   },
//                 }}
//               >
//                 {uniqueItems.map((item) => (
//                   <MenuItem key={item} value={item}>
//                     <Checkbox checked={selectedItems.indexOf(item) > -1} />
//                     <ListItemText primary={item} />
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Box>

//           <DndContext 
//             sensors={sensors}
//             collisionDetection={closestCenter}
//             onDragEnd={handleDragEnd}
//           >
//             <SortableContext 
//               items={charts.map(chart => chart.id)}
//               strategy={verticalListSortingStrategy}
//             >
//               <Box 
//                 display="flex" 
//                 flexWrap="wrap" 
//                 justifyContent="flex-start" 
//                 gap="20px" // Adjusted the gap between charts
//               >
//                 {charts.map((chart) => (
//                   <SortableItem key={chart.id} id={chart.id}>
//                     {chart.id === 'pieChart' || chart.id === 'lineChart' ? (
//                       <Box width="45%" minWidth="300px">
//                         {/* <Typography variant="h6">{chart.id === 'pieChart' ? 'Pie Chart' : 'Line Chart'}</Typography> */}
//                         <chart.component data={dashboardData?.charts.find(c => c.chart_type === (chart.id === 'pieChart' ? 'pie_chart' : 'line_chart'))?.data || []} />
//                       </Box>
//                     ) : (
//                       <chart.component selectedItems={selectedItems} />
//                     )}
//                   </SortableItem>
//                 ))}
//               </Box>
//             </SortableContext>
//           </DndContext>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Dashboard;
