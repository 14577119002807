import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import logoImage from '../assets/White-icon.png'; // Importing the image

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #090B1E, #2B1242);
  overflow: hidden;
`;

const SignUpCard = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
`;

const LogoContainer = styled.div`
 display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 20px; /* Adjusted to move right */
  bottom: 20px; /* Adjusted to move up */
  background: radial-gradient(circle, #512C85 0%, #341F67 60%, transparent 80%); /* Circular gradient around the logo */
  width: 400px; /* Set width for the gradient background */
  height: 400px; /* Set height for the gradient background */
  border-radius: 50%; /* To keep the background circular */
`;

const SpiralContainer = styled.div`
  height: 500px;
  width: 100px;
  background: radial-gradient(#9900FF, #7A00CC, #5C0099);
  backdrop-filter: blur(12.26px);
  border-radius: 50%;
  margin: 100px 50px 0 0;
  position: absolute;
  top: -200px;
  right: -200px;
  z-index: 0;
`;

const Logo = styled.div`
  width: 350px;
  height: 350px;
  background: url(${logoImage}) no-repeat center/contain; /* Using the imported image */
`;

const SignUpForm = styled.div`
  width: 550px;
  height: 650px;
 background: rgba(255, 255, 255, 0.1); /* Adjusted transparency */
  backdrop-filter: blur(10px); /* Slight blur to match the effect */
  padding: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  position: absolute;
  right: 100px;
  top: 50px;
  z-index: 1;
`;

const Title = styled.h1`
  margin: 0 0 20px;
  font-size: 24px;
  text-align: center; /* Center align text */
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  color: white;
  font-size: 16px;
`;

const Input = styled.input`
  width: 400px;
  height: 10px;
  max-width: 500px; /* Adjust this value to set the max width of input boxes */
  padding: 20px 15px;
  margin-top: 5px;
  border: 2px solid #fff;
  border-radius: 25px;
  background: transparent;
  color: white;
  outline: none;
`;

const Button = styled.button`
  align-self: center;
  width: 48%; /* Set width to 48% for both buttons to fit in the container */
  padding: 15px;
  border: 1px;
  border-radius: 40px;
  background: linear-gradient(to right, #9900ff, #5c0099);
  color: white;
  cursor: pointer;
  margin: 20px 1%; /* Added margin for spacing between buttons */
`;

const SignUp = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate(); // Initialize the navigate function

  const handleSubmit = async (e) => {
    e.preventDefault();

    const signUpData = {
      first_name: firstName, // Map the fields to the required keys
      last_name: lastName,
      email: email,
      password: password,
      confirm_password: confirmPassword
    };

    try {
      const response = await fetch('http://20.83.166.134:9011/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signUpData), // Serialize the object to JSON
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Sign-up successful', result);
        navigate('/confirm'); // Redirect to /confirm on success
      } else {
        console.error('Sign-up failed');
        // Handle sign-up failure, e.g., show an error message
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle network errors
    }
  };

  return (
    <Container>
      <SignUpCard>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <SignUpForm>
          <Title>Sign Up</Title>
          <p style={{ textAlign: 'center' }}>Create your account to get started</p>
          <form onSubmit={handleSubmit}>
            <Label>
              First Name
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Label>
            <Label>
              Last Name
              <Input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Label>
            <Label>
              Email Address
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Label>
            <Label>
              Password
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Label>
            <Label>
              Confirm Password
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Label>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button type="button">Back</Button>
              <Button type="submit">Next</Button>
            </div>
          </form>
        </SignUpForm>
        <SpiralContainer />
      </SignUpCard>
    </Container>
  );
};

export default SignUp;
