import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "#2e2e3e", padding: "10px", borderRadius: "5px", color: "#bb86fc" }}>
        <p>{`${payload[0].value.toLocaleString()}`}</p>
        {/* <p>{`${payload[0].name}: ${payload[0].value.toLocaleString()}`}</p> */}
      </div>
    );
  }
  return null;
};

const HorizontalBarChartComponent = ({ data, title }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const formattedData = data.map(item => ({
    name: item.name,
    value: parseFloat(item.value.toFixed(2))
  }));

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Paper sx={{
      padding: "20px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      color: "#FFFFFF",
      borderRadius: "15px",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
      width: "100%", 
      maxWidth: "600px", 
      margin: "auto"
    }}>
      <Typography variant="h6" sx={{ marginBottom: "15px", fontWeight: "bold" , fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" }}}>
        {title || "Horizontal Bar Chart"}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart layout="vertical" data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#444" />
          <XAxis type="number" stroke="#bbbbbb" tick={{ fill: "#bbbbbb" }} tickFormatter={(value) => `${value.toLocaleString()}`} />
          <YAxis dataKey="name" type="category" width={80} stroke="#bbbbbb" tick={{ fill: "#bbbbbb" }} />
          <Tooltip 
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ backgroundColor: 'transparent', border: 'none' }}
          />
          <Bar 
            dataKey="value" 
            fill="url(#colorUv)" 
            radius={[0, 10, 10, 0]} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {formattedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill="url(#colorUv)"
                style={{ filter: index === activeIndex ? 'brightness(1.3)' : 'none' }} // Increase brightness on hover
              />
            ))}
          </Bar>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
              <stop offset="5%" stopColor="#bb86fc" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#6c63ff" stopOpacity={0.8} />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default HorizontalBarChartComponent;
