import React, { useState } from "react";
import { Typography, IconButton, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import Chart from "react-apexcharts";
import { RiExpandDiagonalLine } from "react-icons/ri";

const LineChartComponent = ({ data, title, showDashedLine,label_yaxis,isRevenue  }) => {
  const [isOpen, setIsOpen] = useState(false);

  const years = [...new Set(data.flatMap(item => Object.keys(item).filter(key => key !== 'Month')))];

   // Create series data with filtered zero values
  const series = years.map(year => {
    const yearData = data.map(item => ({
      x: item.Month,
      y: item[year] || null // Convert undefined/falsy values to null
    }));

    // Filter out points where value is 0
    const filteredData = yearData.filter(point => point.y !== 0);

    return {
      name: year,
      data: filteredData
    };
  });

  const formatAxisLabel = (value) => {
    if (value === null || value === undefined) return "N/A";
    return isRevenue ? `${Number(value).toFixed(0)}` : Number(value).toFixed(0);
  };

  const formatTooltip = (value) => {
    if (value === null || value === undefined) return "N/A";
    return isRevenue ? `${Number(value).toFixed(2)}` : Number(value).toFixed(0);
  };


  const getChartOptions = (isExpanded) => ({
    chart: {
      type: 'line',
      zoom: { enabled: false },
      toolbar: { show: false },
      background: 'transparent',
      animations: { enabled: false },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      dashArray: showDashedLine ? [0, 5] : [0, 0],
    },
    colors: ['#bb86fc', '#5A639C'],
    xaxis: {
      title: {
        text: 'Month',
        style: { color: '#bbbbbb' },
        offsetY: -20,
      },
      categories: data.map(item => item.Month),
      labels: {
        style: {
          colors: '#bbbbbb',
          fontSize: isExpanded ? '14px' : '12px',
        },
        rotateAlways: isExpanded ? false : true,
        rotate: isExpanded ? 0 : -45, 
        
      },
    },
    yaxis: {
      title: {
        text: label_yaxis,
        style: { color: '#bbbbbb' }
      },
      labels: {
        formatter: value => formatAxisLabel(value),
                style: {
          colors: '#bbbbbb',
          fontSize: isExpanded ? '14px' : '12px',
        },
      },
    },
    tooltip: {
      theme: 'dark',
      shared: true,
      intersect: false,
      style: { fontSize: '12px' },
      y: {
        formatter: (value) => formatTooltip(value)
      },
    },
    grid: {
      borderColor: '#444',
      yaxis: { lines: { show: true } },
      xaxis: { lines: { show: true } },
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      labels: { colors: '#bbbbbb' },
      fontSize: isExpanded ? '14px' : '12px',
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }]
  });

  const toggleModal = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Typography 
        variant="h6" 
        sx={{ marginBottom: "15px", fontWeight: "bold", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {title || "Line Chart"}
        {/* <IconButton size="small" onClick={toggleModal} sx={{ color: '#fff' }}>
          <RiExpandDiagonalLine />
        </IconButton> */}
      </Typography>

      <div style={{ height: '300px' }}>
        <Chart options={getChartOptions(false)} series={series} type="line" height="100%" />
      </div>

      <Dialog
        open={isOpen}
        onClose={toggleModal}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "#1a1a2e",
            color: "#FFFFFF",
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
          {title || "Expanded Line Chart"}
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <div style={{ height: '500px', width: '100%' }}>
            <Chart options={getChartOptions(true)} series={series} type="line" height="100%" width="100%" />
          </div>
        </DialogContent>
        <Button onClick={toggleModal} color="primary" sx={{ color: '#fff', m: 2 }}>Close</Button>
      </Dialog>
    </div>
  );
};

export default LineChartComponent;
