import React from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';

// Initialize the waterfall module
HC_more(Highcharts);

const WaterfallChart = ({ data = [], categories = [] ,chartTitle = 'Waterfall Chart' }) => {
  const options = {
    chart: {
      type: 'waterfall',
      width: 750,
      height: 320,
      backgroundColor: 'transparent', 
    plotBackgroundColor: 'transparent', 
    marginTop: 0, // Set the plot area background to transparent
    },
    // title: {
    //   text: chartTitle,
    //   style: {
    //     color: 'white', // Make the title font color white
    //   },
    // },
    xAxis: {
      type: 'category',
      categories: categories, // Dynamic categories from API
      labels: {
        style: {
          color: 'white',
          rotation: -45, // Set X-axis labels to white
         
        },
      },
    },
    yAxis: {
      title: {
        text: 'Sales',
      },
      labels: {
        style: {
          color: 'white', 
        },
      },
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: 'white', 
      },
    },
    credits: {
        enabled: false 
      },
    tooltip: {
      pointFormat: '<b>{point.y:,.2f}</b> Sales',
    },
    series: [
      {
        name: 'Increase/Decrease',
        upColor: '#E2BBE9',  // Green for increases
        color: '#f39c12',    // Orange for decreases
        data: data,  // Dynamic data from API
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'white', // Set the text color for the data labels to white
            textOutline: 'none',
          },
        },
        pointPadding: 0,
        pointWidth: 40,
        marker: {
            symbol: 'circle',  
            fillColor: '#E2BBE9', 
          },
        
      },
     
    ],
  };

  return (
    <div style={{ maxWidth: '1000px', margin: 'auto', }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default WaterfallChart;
