import React, { useState } from 'react';
import styled from 'styled-components';
import logoImage from '../assets/White-icon.png'; // Ensure the correct path

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center; /* Align ConfirmCard to the right */
  align-items: center;
  background: radial-gradient(circle, #512C85 0%, #341F67 60%, transparent 80%); 
  overflow: hidden;
  position: relative; /* Make this position relative to position the logo */
`;

const ConfirmCard = styled.div`
  width: 500px;
  height: 600px;
  background: rgba(255, 255, 255, 0.1); /* Adjusted transparency */
  backdrop-filter: blur(10px); 
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Space out elements vertically */
  border-radius: 25px;
  text-align: center;
`;

const LogoContainer = styled.div`
  width: 500px;
  height: 350px;
  background: url(${logoImage}) no-repeat center/contain;
  position: absolute;
  bottom: 20px; /* Align to the bottom */
  left: 20px; /* Align to the left */
`;

const Input = styled.input`
  width: 60px;
  height: 60px;
  margin: 10px;
  border: 2px solid white;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 24px;
  text-align: center;
  outline: none;
`;

const Button = styled.button`
  width: 48%; /* Set width to 48% for both buttons to fit in the container */
  padding: 15px;
  border: none;
  border-radius: 40px;
  background: linear-gradient(to right, #6A27AD, #391B7C); 
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin: 20px 1%; /* Spacing between buttons */
`;

const ConfirmAccount = () => {
  const [code, setCode] = useState(Array(4).fill(''));

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle confirmation logic here
  };

  return (
    <Container>
      <LogoContainer />
      <ConfirmCard>
        <div>
          <h2>Confirm your account</h2>
          <p>Enter the 4-digit confirmation code that was sent to your email</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {code.map((digit, index) => (
              <Input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                required
              />
            ))}
          </div>
          <Button type="button" style={{ marginTop: '20px' }}>Resend code</Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button type="button">Back</Button>
          <Button type="submit">Next</Button>
        </div>
      </ConfirmCard>
    </Container>
  );
};

export default ConfirmAccount;
