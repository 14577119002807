import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Collapse, CircularProgress, IconButton } from '@mui/material';
import { ChevronDown, ChevronRight, Plus } from 'lucide-react';
import { styled } from '@mui/system';
import { API_URL } from '../constants/AppConstants';
import zIndex from '@mui/material/styles/zIndex';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';

const ScrollableBox = styled(Box)(({ theme }) => ({
  width: '250px',
  height: '100vh',
  bgcolor: '#1a1a2e',
  color: '#fff',
  padding: '16px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255, 255, 255, 0.5)',
  },
  '@media (max-width: 768px)': {
    position:'fixed',
    zIndex:'1000',
    paddingLeft:'50px'
  },
}));


const CustomizableSidebar = ({ onAddChart }) => {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [hoveredMetric, setHoveredMetric] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const addMetricToCarousel = (selectedMetric) => {
    
    onAddChart(selectedMetric)
    setSelectedMetric(selectedMetric); 
  }
  
  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const user_id = localStorage.getItem('user_id');
        
        
        const storedMetrics = localStorage.getItem('metricsData');
        
        if (storedMetrics) {
          const parsedMetrics = JSON.parse(storedMetrics);
          setMetrics(parsedMetrics);
          
          const initialExpanded = Object.keys(parsedMetrics).reduce((acc, category) => {
            acc[category] = true;
            return acc;
          }, {});
          setExpandedCategories(initialExpanded);
          setLoading(false);
        } else {
          const response = await fetch(`${API_URL}/listMetrics`, {
            method: 'POST', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: user_id,
            }),
          });
          const data = await response.json();
          
          setMetrics(data.Data);
        
          localStorage.setItem('metricsData', JSON.stringify(data.Data));
        
          const initialExpanded = Object.keys(data.Data).reduce((acc, category) => {
            acc[category] = true;
            return acc;
          }, {});
          setExpandedCategories(initialExpanded);
          setLoading(false);
        }
      } catch (error) {
        
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  // const addSalesByMenuCategory = () => {
  //   const newChart = generateSalesByMenuCategoryData(); 
  //   onAddChart(newChart);
  // };

  const renderMetrics = (category, metricsList) => (
  <Collapse in={expandedCategories[category]}>
    <List disablePadding>
      {metricsList.map((metric) => {
        
        

        return (
          <ListItem
            key={metric}
            onMouseEnter={() => setHoveredMetric(metric)}
            onMouseLeave={() => setHoveredMetric(null)}
            sx={{
              pl: 4,
              py: 0.5,
              '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {metric.enabled !== true ? (
              <ListItemText 
              primary={metric.MetricDisplayName} 
              primaryTypographyProps={{ 
                variant: 'body2',
                sx: { color: '#6b6767', opacity: 0.5 }
              }} />) :
              (
                <ListItemText 
              primary={metric.MetricDisplayName} 
              primaryTypographyProps={{ 
                variant: 'body2',
                sx: { color: '#b8b8b8' }
              }} />
              )
            
            }
           
            
            {hoveredMetric === metric && category === 'Advanced' ? (
              <IconButton
                size="small"
                onClick={() => {
                  addMetricToCarousel(metric);
                }}
                sx={{ color: 'violet' }}
              >
                <RemoveRedEyeRoundedIcon size={10} />
              </IconButton>
            ) : hoveredMetric === metric & metric.enabled === true?
            (
              <IconButton
                size="small"
                onClick={() => {
                  addMetricToCarousel(metric);
                }}
                sx={{ color: 'white' }}
              >
                <Plus size={16} />
              </IconButton>
            ) :  metric.enabled === true?
            (
              
                <IconButton
                size="small"
                onClick={() => {
                  addMetricToCarousel(metric);
                }}
                sx={{ color: 'white' }}
              >
              </IconButton>
              
              
            ): null}

          </ListItem>
        );
      })}
    </List>
  </Collapse>
);
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ScrollableBox 
    sx={{ width: '250px',
      height: '100vh',
      bgcolor: '#1a1a2e',}}>
    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', ml:2 }}>
      Customize Dashboard
    </Typography>

      {Object.entries(metrics).map(([category, metricsList]) => (
        <Box key={category} sx={{ mb: 2 ,ml: 2}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
            }}
            onClick={() => toggleCategory(category)}
          >
            {expandedCategories[category] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', ml: 1 }}>
              {category}
            </Typography>
          </Box>

          {renderMetrics(category, metricsList)}
        </Box>
      ))}
    </ScrollableBox>
  );
};

export default CustomizableSidebar;