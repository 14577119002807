import { Box } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Define a keyframe animation for horizontal growth
const growAnimation = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

// Create a styled Skeleton component with the animation
const AnimatedSkeleton = styled(Skeleton)`
  animation: ${growAnimation} 1.5s ease-in-out forwards; // 'forwards' keeps the final state
`;

export const DescriptionLoader = () => {
    return (
        <Box>
            <AnimatedSkeleton 
                variant="text" 
                sx={{ 
                    fontSize: '0.9rem', 
                    width: { xs: '150px', sm: '250px', lg: '100%' }, 
                    bgcolor: '#dabfff', 
                    opacity: 0.3,
                    mb: 1
                }} 
            />
            <AnimatedSkeleton 
                variant="text" 
                sx={{ 
                    fontSize: '0.9rem', 
                    width: { xs: '120px', sm: '200px', lg: '500px' }, 
                    bgcolor: '#dabfff', 
                    opacity: 0.3,
                    mb: 1
                }} 
            />
            <AnimatedSkeleton 
                variant="text" 
                sx={{ 
                    fontSize: '0.9rem', 
                    width: { xs: '100px', sm: '180px', lg: '480px' }, 
                    bgcolor: '#dabfff', 
                    opacity: 0.2,
                    mb: 1
                }} 
            />
            <AnimatedSkeleton 
                variant="text" 
                sx={{ 
                    fontSize: '0.9rem', 
                    width: { xs: '80px', sm: '150px', lg: '400px' }, 
                    bgcolor: '#dabfff', 
                    opacity: 0.1,
                    mb: 1
                }} 
            />
            <AnimatedSkeleton 
                variant="text" 
                sx={{ 
                    fontSize: '0.9rem', 
                    width: { xs: '60px', sm: '120px', lg: '280px' }, 
                    bgcolor: '#dabfff', 
                    opacity: 0.1,
                    mb: 1
                }} 
            />
        </Box>
    );
};
