import React from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ChatIcon from '@mui/icons-material/Chat'; // Import a chat icon

// Create a styled IconButton that looks like a chat bubble
export const ChatBubbleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#f0f0f0', // Background color of the button
  borderRadius: '20px', // Rounded corners for the bubble effect
  padding: '10px', // Padding to make it look like a bubble
  position: 'relative',
  '&:hover': {
    backgroundColor: '#e0e0e0', // Change color on hover
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px', // Position the tail of the bubble
    left: '15px', // Adjust based on padding
    width: '0',
    height: '0',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: '8px solid #f0f0f0', // Same color as the button
  },
}));
