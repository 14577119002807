import React, { useState, useMemo, useEffect } from "react";
import { Paper, Typography, IconButton, Dialog, DialogContent, DialogTitle, Button, DialogContentText, DialogActions, Box, useTheme,Popper } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Cell, Label } from "recharts";
import { RiExpandDiagonalLine, RiCloseLine } from "react-icons/ri";
import { TouchApp,Close  } from "@mui/icons-material";
import { API_URL } from '../constants/AppConstants';
import { useMediaQuery } from '@mui/material';
import { DescriptionLoader } from "./charts/DescriptionLoader";
import { ChartDescription } from "./charts/ChartDescription";
import logoSvg from '../assets/logo.svg';
import qIcon from '../assets/Qicon.png';
const COLORS = [
  '#815ac0', '#c19bff', '#7251b5','#d2b7e5','#a06cd5','#b185db'
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "rgba(46, 46, 62, 0.8)", padding: "10px", color: "#FFFFFF", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)", fontSize: "12px" }}>
        <p style={{ margin: "0", fontWeight: "bold" }}>{label}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ margin: "5px 0 0", color: entry.color }}>
            {`${entry.name}: ${entry.value.toFixed(2)}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const ChartContent = ({ data, xAxisKey, keys, width, height, onBarClick, isExpanded, YAxisKey}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 10, bottom: isMobile ? 60 : 20 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="#444" />
        <XAxis 
          dataKey={xAxisKey} 
          tick={{ 
            fill: "#bbbbbb",
            fontSize: isMobile ? 10 : 12, 
            angle: isMobile ? -45 : 0,
            textAnchor: isMobile ? 'end' : 'middle',
            dy: isMobile ? 10 : 0
          }} 
          height={isMobile ? 60 : 30}
          interval={0}
          tickFormatter={(value) => value}
        >
           <Label value={xAxisKey} offset={-10} position="insideBottom" fill="#bbbbbb" />
        </XAxis>
        <YAxis tick={{ fill: "#bbbbbb", fontSize: 12 }} >
        <Label value={YAxisKey} angle={-90} position="insideLeft" fill="#bbbbbb" />
          </YAxis>
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
        {keys.map((key, index) => (
          <Bar key={key} dataKey={key} stackId="a" fill={COLORS[index % COLORS.length]} onClick={onBarClick}>
            {data.map((entry, dataIndex) => (
              <Cell key={`cell-${dataIndex}`} style={isExpanded ? { cursor: 'pointer' } : {}} />
            ))}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const StackedBarChartComponent = ({ data, title, description, onRemove, MetricId, displayname, chartType, xAxisKey, YAxisKey}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [drillDownData, setDrillDownData] = useState(null);
  const [isDrillDown, setIsDrillDown] = useState(false);
  const [drillDownTitle, setDrillDownTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [isDescLoading, setIsDescLoading] = useState(false);
  const [isDrillDownSummaryLoading, setIsDrillDownSummaryLoading] = useState(false);
  const [drillDownSummary, setDrillDownSummary] = useState("");
  const [role_id, setRoleId] = useState(localStorage.getItem('role_id'));
  const [descEnabled, setDescEnabled] = useState(false);
  const [isDescriptionPopupOpen, setIsDescriptionPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery('(max-width:320px)');
 
  const xAxisKeyLabel = isDrillDown ? "Date" : xAxisKey;
  
  const getKeys = useMemo(() => {
    const allKeys = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== xAxisKey && key !== 'from_date' && key !== 'to_date') {
          allKeys.add(key);
        }
      });
    });
    return Array.from(allKeys);
  }, [data, xAxisKey]);

  const fetchSummary = async (isDrillDownView = false) => {
    setIsDescLoading(true);
    try {
      const dataToSend = isDrillDownView ? drillDownData : data;
      const response = await fetch(`${API_URL}/summariserData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            MetricId,
            MetricDisplayName: title,
            MetricDescription: description,
            MetricDetails: {
              title,
              chart_type: chartType,
              x_axis: xAxisKey,
              data: dataToSend,
            },
            isDrillDown: isDrillDownView
          }
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      if (isDrillDownView) {
        setDrillDownSummary(result.summarised_data);
      } else {
        setDesc(result.summarised_data);
      }
    } catch (error) {
      console.error('Error fetching summary:', error);
    } finally {
      setIsDescLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setDescEnabled(false);
    }
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (onRemove) {
      onRemove(MetricId);
    }
    handleCloseDeleteDialog();
  };

  const handleBarClick = async (barData) => {
    if (role_id === '1' && !isDrillDown && isOpen) {
      try {
        const user_id = localStorage.getItem('user_role_id');
        const fromDate = barData.payload.from_date;
        const toDate = barData.payload.to_date;
        
        const response = await fetch(`${API_URL}/LoadMetricsToCanvas`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_role_id: [user_id],
            role_id: role_id,
            summary: "",
            metrics: [
              {
                "MetricId": MetricId,
                "MetricDisplayName": displayname
              }
            ],
            fromDate,
            toDate,
            "drilldown": true
          }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const result = await response.json();
        const metricDetails = result.metricDetails[0].MetricDetails;
        setDrillDownData(metricDetails.data);
        setDrillDownTitle(metricDetails.title || "Daily Breakdown");
        setIsDrillDown(true);
        setDescEnabled(false);
      } catch (error) {
        console.error('Error fetching drill-down data:', error);
      }
    }
  };

  const handleBackToPrevious = () => {
    setDrillDownData(null);
    setIsDrillDown(false);
    setDrillDownTitle("");
    setDescEnabled(false);
  };

  const currentModalData = isDrillDown ? drillDownData : data;
  const currentKeys = getKeys;

  const handleQIconClick = () => {
    setDescEnabled(true);
    fetchSummary(isDrillDown);
  };
  const handleQClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopperOpen(true);
    setDescEnabled(true);
    fetchSummary(isDrillDown);
  };
  const handleClosePopper = (event) => {
    console.log("Close button clicked");
    // Prevent the event from bubbling up to the ClickAwayListener
    if (event) {
      event.stopPropagation();
    }
    setPopperOpen(false);
    setAnchorEl(null);
    setDescEnabled(false);
  };
  return (
    <>
      <Paper
        sx={{
          padding: isExtraSmall ? "10px" : "20px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          color: "#FFFFFF",
          borderRadius: "15px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
          width: isExtraSmall ? "280px" : isMobile ? "300px" : "350px",
          transition: "transform 0.3s ease",
          '&:hover': {
            transform: 'scale(1.05)',
            zIndex: 1
          },
          position: 'relative',
        }}
      >
        <Typography variant="h6" sx={{
          marginBottom: "15px",
          fontWeight: "bold",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: isExtraSmall ? '0.8rem' : isMobile ? '0.9rem' : '1rem'
        }}>
          {title || "Stacked Bar Chart"}
          <div>
            <IconButton size="small" onClick={toggleModal} sx={{ color: '#fff', mr: 1 }}>
              <RiExpandDiagonalLine />
            </IconButton>
            <IconButton size="small" onClick={handleOpenDeleteDialog} sx={{ color: '#fff' }}>
              <RiCloseLine />
            </IconButton>
          </div>
        </Typography>
  
        <ChartContent data={data} xAxisKey={xAxisKeyLabel} keys={getKeys} width="100%" height={310} isExpanded={false} YAxisKey={YAxisKey}/>
        <IconButton 
          onClick={handleQClick}
          sx={{
            position: 'absolute',
            right: '-15px',
            bottom: '-15px',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
          }}
        >
          <img src={qIcon} alt="Q Icon" style={{ width: '35px', height: '35px' }} />
        </IconButton>
      </Paper>
      <Popper
        open={popperOpen}
        anchorEl={anchorEl}
        placement={isExtraSmall ? "top" : "left-start"}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: isExtraSmall ? [0, -10] : [-10, 0],
            },
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['bottom', 'right'],
            },
          },
        ]}
        style={{ zIndex: 1300 }}
      >
        <Box
          sx={{
            padding: isExtraSmall ? 1.5 : 2,
            backgroundColor: "#FFFFFF",
            color: "#333",
            width: isExtraSmall ? '250px' : '300px',
            maxWidth: '100%',
            maxHeight: isExtraSmall ? '50vh' : 400,
            overflow: 'auto',
            position: 'relative',
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            zIndex: 1500,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="h6" sx={{ 
              fontWeight: 'bold', 
              color: '#a084e8', 
              textAlign: 'left', 
              flexGrow: 1, 
              margin: 0,
              fontSize: isExtraSmall ? '0.9rem' : '1rem'
            }}>
              Chart Description
            </Typography>
            <IconButton
              onClick={handleClosePopper}
              sx={{
                color: '#333',
                padding: '4px',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
              }}
            >
              <Close fontSize={isExtraSmall ? "small" : "medium"} />
            </IconButton>
          </Box>
          {isDescLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '50%', my: 2 }}>
              <DescriptionLoader />
            </Box>
          ) : (
            <Box sx={{ width: '100%', textAlign: 'left', margin: 0, padding: 0 }}> 
              <ChartDescription desc={isDrillDown ? drillDownSummary: desc} />
            </Box>
          )}
        </Box>
      </Popper>
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { backgroundColor: "#1a1a2e", color: "#FFFFFF" } }}
      >
        <DialogTitle sx={{
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          fontSize: { xs: '0.9rem', sm: '1.5rem' }
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {isDrillDown ? drillDownTitle : (title || "Expanded Stacked Bar Chart")}
            {role_id === '1' && !isDrillDown && (
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <TouchApp sx={{ ml: 1, color: '#E2BBE9', fontSize: { xs: '0.8rem', sm: '1.2rem' } }} />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#E2BBE9',
                    whiteSpace: 'normal',
                    fontSize: { xs: '0.8rem', sm: '0.875rem' }
                  }}
                >
                  Click on a bar to see the drill-down data
                </Typography>
              </Box>
            )}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'flex-start' },
            mb: 2
          }}>
            <Box sx={{ width: '100%' }}>
              <img src={logoSvg} alt="Logo" style={{ width: '42px', height: '41px', cursor: 'pointer' }} onClick={handleQIconClick}/>

              {descEnabled && (isDescLoading || isDrillDownSummaryLoading) ? (
                <Box sx={{ display: 'flex', justifyContent: 'left', width: '100%', my: 2 }}>
                  <DescriptionLoader />
                </Box>
              ) : descEnabled ? (
                <ChartDescription desc={isDrillDown ? drillDownSummary : desc}/>
              ) : null}

              {isDrillDown && (
                <Button onClick={handleBackToPrevious} color="primary" sx={{ color: '#fff', mb: 2, fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  Back to Weekly View
                </Button>
              )}
            </Box>
          </Box>
          <ChartContent
            data={currentModalData}
            xAxisKey={xAxisKeyLabel}
            keys={currentKeys}
            width="100%"
            height={400}
            onBarClick={handleBarClick}
            isExpanded={true}
            YAxisKey={YAxisKey}
          />
        </DialogContent>
        <Button onClick={toggleModal} color="primary" sx={{ color: '#fff', m: 2, fontSize: { xs: '0.9rem', sm: '1rem' } }}>Close</Button>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#1a1a2e",
            color: "#FFFFFF",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem' } }}>
            Are you sure you want to delete this chart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StackedBarChartComponent;