import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from "recharts";

const COLORS = ['#bb86fc', '#9c7afc', '#7e6efc', '#6c63ff', '#564acc', '#453aa8'];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "#2e2e3e", padding: "10px", borderRadius: "5px", color: "#bb86fc" }}>
        <p>{`${payload[0].name}: ${payload[0].payload.cost.toLocaleString()}`}</p>
      </div>
    );
  }
  return null;
};

const GroupedBarChartComponent = ({ data, title }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const formattedData = data.map((item, index) => ({
    ...item,
    cost: parseFloat(item.cost.toFixed(2)), 
    color: COLORS[index % COLORS.length] 
  }));

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Paper sx={{
      padding: "20px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      color: "#FFFFFF",
      borderRadius: "15px",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
      width: "100%", 
      maxWidth: "600px", 
      margin: "auto"
    }}>
      <Typography variant="h6" sx={{ marginBottom: "15px", fontWeight: "bold", fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" }}}>
        {title || "Sales By Category"}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={formattedData} margin={{ right: 30, left: 25, bottom: 30 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.1)" />
          <XAxis dataKey="name" tick={{ fill: "#FFFFFF" }} />
          <YAxis tick={{ fill: "#FFFFFF" }} tickFormatter={(value) => `${value}`} />
          <Tooltip 
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ backgroundColor: 'transparent', border: 'none' }}
          />
          <Legend wrapperStyle={{ color: "#FFFFFF" }} />
          <Bar
            dataKey="cost" 
            fill="url(#colorGradient)" 
            radius={[10, 10, 0, 0]}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {formattedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                style={{ filter: index === activeIndex ? 'brightness(1.2)' : 'none' }} 
              />
            ))}
          </Bar>
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#bb86fc" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#6c63ff" stopOpacity={0.8} />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default GroupedBarChartComponent;
