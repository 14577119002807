import React from 'react';
import styled from 'styled-components';
import { Drawer, List, ListItem, ListItemIcon, Avatar, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import DocumentIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';
import GridIcon from '@mui/icons-material/GridOn';
import NotificationIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import SyncIcon from '@mui/icons-material/Sync';
import logoSvg from '../assets/logo.svg';
import userLogo from '../assets/user-logo.png';
import nsLogoSvg from '../assets/nslogo.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  
  const handleIconClick = () => {
    navigate('/reports');
  };

  const handleQIconClick = () => {
    navigate('/answer');
  };

  const handleHomeIconClick = () => {
    navigate('/talkToData');
  };

  const HintBubble = styled.div`
    position: absolute;
    bottom: 100%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: black; /* Bubble background color */
    color: white; /* Text color */
    padding: 8px 12px; /* Padding for the bubble */
    border-radius: 8px; /* Rounded corners */
    white-space: nowrap; /* Prevent text wrapping */
    opacity: 0; /* Start hidden */
    transition: opacity 0.2s ease-in-out;
    pointer-events: none; /* Prevent mouse events on the bubble */
    z-index: 10; /* Ensure it appears above other elements */

    /* Arrow pointing to the icon */
    &::after {
        content: '';
        position: absolute;
        top: 100%; /* Position at the bottom of the bubble */
        left: 50%; /* Center horizontally */
        margin-left: -5px; /* Center arrow */
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent; /* Triangle pointing downwards */
    }
`;

return (
  <Drawer
    variant="permanent"
    sx={{
      width: '80px',
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: '80px',
        backgroundColor: '#1e1e2e',
        height: '100vh',
      },
    }}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px 0',
      justifyContent: 'space-between',
      height: '100%'
    }}>
      <img src={nsLogoSvg} alt="Logo"  onClick={handleHomeIconClick} style={{ width: '42px', height: '41px', cursor: 'pointer' }} />
      <List sx={{ width: '100%', marginTop: '30px' }}>
        {location.pathname === '/answer' && (
          <ListItem
            button
            key='home'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '15px 0',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: '#fff',
                minWidth: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                padding: '10px',
              }}
            >
              <Tooltip title="Back to landing page">
                <HomeIcon
                  onClick={handleHomeIconClick}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
        )}
        {location.pathname !== '/answer' && (
          <ListItem
            button
            key='talk-to-data'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '15px 0',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
          >
    
            <ListItemIcon
              sx={{
                color: '#fff',
                minWidth: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                padding: '10px',
              }}
            >
              <Tooltip title="Talk To Your Data">
                <img 
                  src={logoSvg} 
                  alt="Logo" 
                  style={{ width: '28px', height: '28px', cursor: 'pointer' }} 
                  onClick={handleQIconClick}
                />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
        )}
        <ListItem
          button
          key='reports'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '15px 0',
            '&:hover': {
              transform: 'scale(1.2)',
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: '#fff',
              minWidth: 'auto',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <Tooltip title="Generated Report Inventory">
              <DocumentIcon
                onClick={handleIconClick}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>            
          </ListItemIcon>
        </ListItem>
      </List>
      <Avatar sx={{ width: 40, height: 40, marginTop: 'auto', marginBottom: '20px' }} src={userLogo} />
    </Box>
  </Drawer>
);
};


export default Sidebar;
