import React, { useEffect, useState, useRef  } from 'react';
import { Box, Typography } from "@mui/material";
const TypingText = ({ text = '', speed = 100 }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        // Reset displayed text when text changes
        setDisplayedText('');
        let index = 0;

        const typeCharacter = () => {
            if (index < text.length) {
                setDisplayedText((prev) => prev + text.charAt(index));
                index++;
                setTimeout(typeCharacter, speed); // Schedule the next character
            }
        };

        // Start typing only if text is a non-empty string
        if (typeof text === 'string' && text.length > 0) {
            typeCharacter();
        }

        return () => {
            // Cleanup logic if necessary
            index = text.length; // Prevent any further updates
        };
    }, [text, speed]);

    return (
        <Typography 
            variant="body1" 
            sx={{
                mb: { xs: 1, sm: 0 }, 
                mt: 1, 
                flexGrow: 1, 
                maxWidth: { xs: '100%', sm: '70%' },
                fontSize: { xs: '0.5rem', sm: '1rem' }
            }}
        >
            {displayedText}
        </Typography>
    );
}; 


export const useTypewriter = (text, speed: number) => {
    const [displayText, setDisplayText] = useState("");
    const idx = useRef(0);
    const displayTextRef = useRef("");
    useEffect(() => {
      const typingInterval = setInterval(() => {
        if (idx.current < text.length) {
          displayTextRef.current += text.charAt(idx.current);
          setDisplayText(() => displayTextRef.current);
          idx.current += 1;
        } else {
          clearInterval(typingInterval);
        }
      }, speed);
      return () => {
        setDisplayText("");
        clearInterval(typingInterval);
      };
    }, [text, speed]);
    return displayText;
  };


  export const ChartDescription = ({ desc }) => {
    const typeDesc = useTypewriter(desc, 20);
  
    return (
      <Typography 
        variant="body1" 
        sx={{
          width: '100%',
          fontSize: { xs: '0.5rem', sm: '1rem' },
          textAlign: 'justify',
          textJustify: 'inter-word',
          padding: 0,
          margin: 0,
          paddingLeft: 0,
          marginLeft: 0,
          hyphens: 'auto'
        }}
      >
        {typeDesc}
      </Typography>
    );
  };