import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";

const COLORS = ['#E2BBE9','#6C48C5', '#9B86BD','#5A639C']; 

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "#2e2e3e", padding: "10px", borderRadius: "5px", color: "#bb86fc" }}>
        <p>{`Value: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const PieChartComponent = ({ data, title }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const dataKey = Object.keys(data[0]).find(key => key !== 'name');

  const formattedData = data.map(item => ({
    name: item.name,
    value: item[dataKey]
  }));

  return (
    <Paper sx={{
        padding: "20px",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        color: "#FFFFFF",
        borderRadius: "15px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
        width: "350px",
        transition: "transform 0.3s ease", 
        '&:hover': {
          transform: 'scale(1.05)', 
          zIndex: 1 
        }

      }}>
      <Typography variant="h6" sx={{ marginBottom: "15px", fontWeight: "bold" }}>
        {title || "Pie Chart"}
      </Typography>
      <ResponsiveContainer width="100%" height={270}>
        <PieChart>
          <Pie
            data={formattedData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={(_, index) => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(null)}
          >
            {formattedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                style={{
                  filter: activeIndex === index ? 'brightness(1.2)' : 'none'
                }}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default PieChartComponent;
