import React from 'react';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ConfirmAccount from './pages/ConfirmAccount';
import TalkToData from './pages/TalkToData';
import AnswerPage from './pages/AnswerPage'; // Import the new AnswerPage
import FinancialReport from './pages/FinancialReport';

function App() {
  return (
    <Router>
      <Box
        sx={{
          display: 'flex',
          color: 'white',
          background: 'linear-gradient(135deg, #090912 0%, #1e1e2e 50%, #301450 100%)',
          minHeight: '100vh',
          width: '100vw',
        }}
      >
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/confirm" element={<ConfirmAccount />} />
            <Route path="/talktodata" element={<TalkToData />} />
            <Route path="/answer" element={<AnswerPage />} /> 
            <Route path="/reports" element={<FinancialReport />} />


          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
