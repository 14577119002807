import React from "react";
import { Paper, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";


const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "#2e2e3e", padding: "10px",  color: "#bb86fc" }}>
        {payload.map((item, index) => (
          <p key={index}>{`${item.name}: $${item.value.toLocaleString()}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

const StackedBarChartComponent = ({ data, title }) => {
 
  const formattedData = data.map(item => ({
    month: item.month, 
    tax_amount: parseFloat(item.tax_amount.toFixed(2)),
    other_charges: parseFloat(item.other_charges.toFixed(2))
  }));

  return (
    <Paper
    sx={{
      padding: "20px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      color: "#FFFFFF",
      borderRadius: "15px",
      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
      width: "350px",
      transition: "transform 0.3s ease",
      '&:hover': {
        transform: 'scale(1.05)',
        zIndex: 1,
      },
      // '@media (max-width: 800px)': {
      //   width: '300px',
      // },
    }}
  >
      <Typography variant="h6" sx={{ marginBottom: "15px", fontWeight: "bold" }}>
        {title || "Stacked Bar Chart"}
      </Typography>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={formattedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#444" />
          <XAxis
            dataKey="month"
            stroke="#bbbbbb"
            tick={{ fill: "#bbbbbb" }}
            tickFormatter={(value) => new Date(value).toLocaleString('default', { month: 'short', year: 'numeric' })}
          />
          <YAxis
            stroke="#bbbbbb"
            tick={{ fill: "#bbbbbb" }}
            tickFormatter={(value) => `$${value.toLocaleString()}`}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" height={36} />
          <Bar dataKey="service_charge" stackId="a" fill="#bb86fc" name="Service Charge" />
          <Bar dataKey="tax_amount" stackId="a" fill="#03dac6" name="Tax Amount" />
          <Bar dataKey="other_charges" stackId="a" fill="#cf6679" name="Other Charges" />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default StackedBarChartComponent;