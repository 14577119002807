import React, { useState } from 'react';
import { 
  Tabs, Tab, Button, Table, TableHead, TableBody, TableRow, TableCell, 
  IconButton, useMediaQuery, useTheme, Box, Typography 
} from '@mui/material';
import { API_URL } from '../constants/AppConstants';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom'; 

function FinancialReport() {
  const [selectedTab, setSelectedTab] = useState('Financial Report');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const [data, setData] = useState([
    ['A. Revenue', '', ''],
    ['Total Sales', 'Total Amount', 100000],
    ['Food Sales', 'Food Amount', 40000],
    ['Beverage Sales', 'Beverage Amount', 30000],
    ['Other Sales', 'Other Amount (e.g., Merchandise)', 30000],
    ['B. COGS', '', ''],
    ['Food COGS', 'Food COGS', 15000],
    ['Beverage COGS', 'Beverage COGS', 5000],
    ['', '', ''],
    ['C. Gross Profit', 'Total Sales - Total COGS', 80000],
    ['', '', ''],
    ['D. Operating Expenses', '', ''],
    ['Rent', 'Rent Amount', 10000],
    ['Utilities', 'Utilities Amount', 5000],
    ['Salaries and Wages', 'Salaries Amount', 20000],
    ['Marketing', 'Marketing Amount', 3000],
    ['Supplies', 'Supplies Amount', 2000],
    ['Maintenance', 'Maintenance Amount', 1000],
    ['', '', ''],
    ['E. Total Operating Expenses', 'Operating Expenses', 41000],
    ['', '', ''],
    ['F. Operating Income', 'Gross Profit - Operating Expenses', 39000],
    ['', '', ''],
    ['G. Other Income/Expenses', '', ''],
    ['Interest', 'Interest Amount', 2000],
    ['Taxes', 'Taxes Amount', 1000],
    ['', '', ''],
    ['H. Net Income', 'Operating Income - Other Expenses', 36000],
  ]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(`${API_URL}/generate_report`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      
      link.href = url;
      link.setAttribute('download', 'report.xlsx'); 
      
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching report:', error);
    }
  };

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        background: 'linear-gradient(118.12deg, #131B34 0%, #131B34 41.39%, #5B0CA2 94.83%, #9900FF 101.97%)',
        color: '#fff',
        padding: { xs: '10px', sm: '20px' },
        overflowX: 'hidden'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <IconButton onClick={() => navigate('/talktodata')}>
          <ChevronLeftIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Box>
      <Tabs 
        value={selectedTab} 
        onChange={handleTabChange} 
        variant={isMobile || isTablet ? "scrollable" : "standard"}
        scrollButtons="auto"
        allowScrollButtonsMobile
        centered={!isMobile && !isTablet}
        sx={{
          '& .MuiTabs-indicator': { backgroundColor: '#fff' },
          '& .MuiTab-root': { 
            color: '#fff', 
            fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' },
            minWidth: { xs: 'auto', sm: '120px' }
          },
        }}
      >
        <Tab label="Financial Report" value="Financial Report" />
        <Tab label="Executive Summary" value="Executive Summary" />
        <Tab label="Balance Sheet" value="Balance Sheet" />
        <Tab label="Cash Flow Statement" value="Cash Flow Statement" />
        <Tab label="KPIs" value="KPIs" />
        <Tab label="Conclusion" value="Conclusion and Recommendations" />
      </Tabs>
      <Box sx={{ textAlign: 'center', margin: '20px 0' }}>
        {selectedTab === 'Financial Report' && (
          <>
            <Button 
              onClick={handleDownload} 
              variant="contained" 
              sx={{ 
                marginBottom: '20px', 
                background: 'linear-gradient(to right, #8A47CD, #592B9C)', 
                color: '#fff',
                '&:hover': {
                  background: 'linear-gradient(to right, #7A37BD, #491B8C)',
                }
              }}
            >
              Download
            </Button>
            <Box sx={{ overflowX: 'auto' }}>
              <Table sx={{ 
                borderCollapse: 'collapse', 
                width: '100%', 
                minWidth: { xs: '600px', md: '800px' },
                '& th, & td': {
                  padding: { xs: '8px', sm: '12px', md: '16px' },
                  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }
                }
              }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: '1px solid #ddd', backgroundColor: 'rgba(106, 39, 173, 0.8)', color: '#fff', fontWeight: 'bold' }}>Category</TableCell>
                    <TableCell sx={{ border: '1px solid #ddd', backgroundColor: 'rgba(106, 39, 173, 0.8)', color: '#fff', fontWeight: 'bold' }}>Details</TableCell>
                    <TableCell sx={{ border: '1px solid #ddd', backgroundColor: 'rgba(106, 39, 173, 0.8)', color: '#fff', fontWeight: 'bold' }}>Amount (Rs)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((cell, idx) => (
                        <TableCell 
                          key={idx} 
                          sx={{ 
                            border: '1px solid #ddd', 
                            color: '#fff',
                            backgroundColor: 'rgba(106, 39, 173, 0.1)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: { xs: '100px', sm: '150px', md: '200px' }
                          }}
                        >
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
        {selectedTab === 'Executive Summary' && <Typography variant="h6">Executive Summary content.</Typography>}
        {selectedTab === 'Balance Sheet' && <Typography variant="h6">Balance Sheet content.</Typography>}
        {selectedTab === 'Cash Flow Statement' && <Typography variant="h6">Cash Flow Statement content.</Typography>}
        {selectedTab === 'KPIs' && <Typography variant="h6">Key Performance Indicators (KPIs) content.</Typography>}
        {selectedTab === 'Conclusion and Recommendations' && <Typography variant="h6">Conclusion and Recommendations content.</Typography>}
      </Box>
    </Box>
  );
}

export default FinancialReport;